<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('doctor.doctor_slots') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('doctor.doctors') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-7">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       class="form-control"
                       placeholder="ค้นหาด้วยการระบุ Slot ID หรือ Slot No. หรือ Case ID">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="filter('doctor_id', $event.target.value)">>
                <option value="">{{ $t('doctor.dr') }}: {{ $t('doctor.doctor') }}</option>
                <option :value="doctor.id"
                        v-for="doctor in doctors"
                        :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('doctor.all_status') }}</option>
                <option value="0">จอง</option>
                <option value="1">ยืนยัน</option>
                <option value="2">เช็คอิน</option>
                <option value="3">เสร็จสิ้น</option>
                <option value="4">ยกเลิก</option>
              </select>
            </div>
<!--            <div class="col-md-2">
              <div class="d-grid">
                <button type="button" class="waves-effect waves-light btn btn-primary btn-sm">
                  <img src="/images/google-calendar.png" style="height: 17px;"> {{ $t('doctor.sync_google_calender') }}
                </button>
              </div>
            </div>-->
            <div class="col-md-1">
              <div class="d-grid">
                <button type="button"
                        @click="$refs.addNewDoctorSlotModal.show()"
                        class="waves-effect waves-light btn btn-primary btn-sm">+ {{ $t('doctor.add_new') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="slots"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:doctor_full_name="{ row }">
              <img class="rounded h-30 w-30"
                   onerror="this.src='/images/avatar-girl.png'"
                   :src="row.doctor.image ? row.doctor.image : '/images/avatar-girl.png'"> {{ row.doctor.full_name }}
            </template>

            <template v-slot:status="{ row }">
              <slot-status :status="row.status" />
            </template>

            <template v-slot:action="{ row }">
              <a href="#" class="text-muted"
                 @click.prevent="edit(row)"><i class="fas fa-edit"></i></a> |

              <a href="#"
                 @click.prevent="deleteData(row)"
                 class="text-muted">
                <i class="fas fa-trash"></i>
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-doctor-slot-modal
    ref="addNewDoctorSlotModal"
    @added="loadData" />

  <edit-doctor-slot-modal :doctorSlot="currentDoctorSlot"
                          @updated="loadData"
                          ref="editDoctorSlotModal" />
</template>

<script>
import AddNewDoctorSlotModal from "@/views/doctor/modals/AddNewDoctorSlotModal";
import TableComponent from "@/components/TableComponent";
import SlotStatus from "@/views/doctor/components/SlotStatus";
import EditDoctorSlotModal from "@/views/doctor/modals/EditDoctorSlotModal.vue";

export default {
  components: {AddNewDoctorSlotModal, TableComponent, SlotStatus, EditDoctorSlotModal},
  data() {
    return {
      slots: [],
      doctors: [],
      pagination: null,
      currentDoctorSlot: null,
      sortable: {
        order: 'slot_id',
        sort: 'desc'
      },
      queryParams: {
        status: '',
        department_id: '',
        page: 1,
        search: '',
        sort: 'slot_id',
        sort_order: 'desc',
        per_page: 10
      },
      columns: [
        {
          label: this.$t('doctor.slot_id'),
          field: "slot_id",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('doctor.dr'),
          field: "doctor.full_name",
          sortable: true,
          searchable: true,
          slot: true
        },
        {
          label: this.$t('doctor.slot_no'),
          field: "slot_no",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('doctor.date'),
          field: "date",
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('doctor.start_time'),
          field: "start",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('doctor.end_time'),
          field: 'end',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('doctor.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: this.$t('doctor.case_ID'),
          field: 'case_id',
          sortable: false,
          searchable: false,
        },
        {
          label: this.$t('doctor.comment'),
          field: 'comment',
          sortable: false,
          searchable: false,
        },
        {
          label: this.$t('appointments.action'),
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
    this.loadDoctors();
  },
  methods: {
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    },
    loadData() {
      this.slots = [];

      this.axios.get('/doctor-slots', {params: this.queryParams})
        .then((response) => {
          this.slots = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    edit(row) {
      this.currentDoctorSlot = row;
      this.$refs.editDoctorSlotModal.show();
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/doctor-slots/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              this.$t('patients.deleted'),
              this.$t('patients.patient_has_been_deleted'),
              'success'
            )
          });
        }
      });
    },
  }
}
</script>