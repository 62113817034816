<template>
  <div class="modal fade bs-example-modal-lg"
       id="doctor-details-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Doctor Detail
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="box h-p100">
                <div class="box-header small-box-header">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="box-title">Basic Detail</h4>
                    </div>
                    <div class="col-6 text-end">
                      <button class="btn btn-primary-light btn-sm"
                              v-if="!edit"
                              @click="showEdit"><i class="fas fa-edit"></i> Edit Info</button>

                      <button class="btn btn-sm btn-secondary me-1"
                              @click="edit = false"
                              :disabled="loading"
                              v-if="edit">Cancel</button>
                      <button class="btn btn-sm btn-success"
                              :disabled="loading"
                              @click="submit"
                              v-if="edit">Save</button>
                    </div>
                  </div>
                </div>
                <div class="box-body p-3" v-if="doctor">
                  <div class="row mb-3">
                    <div class="col-4"></div>
                    <div class="col-4 text-center">
                      <img :src="doctorImage"
                           class="w-p100">

                      <button type="button"
                              v-if="edit"
                              @click.prevent="chooseImage"
                              class="btn-sm mt-5 waves-effect waves-light btn btn-outline btn-primary">
                        <i class="far fa-image"></i> เปลี่ยนรูป
                      </button>
                    </div>
                  </div>

                  <div class="row" v-if="!edit">
                    <div class="col-12">
                      <table class="table table-borderless">
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> ชื่อแพทย์
                          </td>
                          <td>: {{ doctor.full_name }}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> แผนก
                          </td>
                          <td>: {{ doctor.department.name }}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> เบอร์โทร
                          </td>
                          <td>: {{ doctor.phone }}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> อีเมล
                          </td>
                          <td>: {{ doctor.email }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="row" v-else>
                    <div class="col-12">
                      <table class="table table-borderless">
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> ชื่อแพทย์
                          </td>
                          <td>
                            <div :class="['form-group form-group-sm mb-0', {'error': errorFor('first_name') || errorFor('last_name') }]">
                              <input class="form-control form-control-sm mb-1"
                                     v-model="form.first_name"
                                     type="text">

                              <input class="form-control form-control-sm"
                                     v-model="form.last_name"
                                     type="text">

                              <v-errors :errors="errorFor('first_name')"></v-errors>
                              <v-errors :errors="errorFor('last_name')"></v-errors>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> แผนก
                          </td>
                          <td>
                            <div :class="['form-group form-group-sm mb-0', {'error': errorFor('department_id')}]">
                              <select class="form-select form-select-sm"
                                      v-model="form.department_id">
                                <option value="">เลือก</option>
                                <option :value="department.id"
                                        v-for="department in departments"
                                        :key="'department_'+department.id">{{ department.name }}</option>
                              </select>

                              <v-errors :errors="errorFor('department_id')"></v-errors>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> เบอร์โทร
                          </td>
                          <td>
                            <div :class="['form-group form-group-sm mb-0', {'error': errorFor('phone')}]">
                              <input class="form-control form-control-sm"
                                     v-model="form.phone"
                                     type="text">

                              <v-errors :errors="errorFor('phone')"></v-errors>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style="color:#5156BE;">◆</span> อีเมล
                          </td>
                          <td>
                            <div :class="['form-group form-group-sm mb-0', {'error': errorFor('email')}]">
                              <input class="form-control form-control-sm"
                                     v-model="form.email"
                                     type="text">

                              <v-errors :errors="errorFor('email')"></v-errors>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <button :class="'btn btn-sm me-2 ' + (currentComponent === 'booked' ? 'btn-primary' : 'btn-secondary')"
                      @click="currentComponent = 'booked'">Booked / Open Slot</button>
              <button :class="'btn btn-sm me-2 ' + (currentComponent === 'past-slots' ? 'btn-primary' : 'btn-secondary')"
                      @click="currentComponent = 'past-slots'">Past Slots</button>

              <div class="row mt-3">
                <div class="col-12">
                  <component v-if="currentComponent && doctor"
                             :doctor="doctor"
                             :is="currentComponent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <input class="d-none"
         type="file"
         @change="readURL($event.target)"
         ref="imageInput"
         accept="image/*">
</template>

<script>
import Booked from './doctor_details_components/Booked'
import PastSlots from './doctor_details_components/PastSlots'
export default {
  components: {Booked, PastSlots},
  data() {
    return {
      modal: null,
      currentComponent: null,
      doctor: null,
      edit: false,
      departments: [],
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        department_id: '',
        image: '',
      }
    }
  },
  created() {
    this.axios.get('/departments')
      .then(res => {
        this.departments = res.data.data;
      });
  },
  computed: {
    doctorImage() {
      if (this.edit) {
        if (this.form.image)
          return this.form.image;
        else
          return this.doctor.image ? this.doctor.image : '/images/avatar-girl.png';
      } else {
        return this.doctor.image ? this.doctor.image : '/images/avatar-girl.png'
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('doctor-details-modal'));
  },
  methods: {
    showEdit() {
      this.edit = true;

      this.form = {...this.doctor};
    },
    show(id) {
      this.edit = false;
      this.modal.show();
      this.loadDoctor(id);
      this.currentComponent = 'booked'
    },
    hide() {
      this.modal.hide();
    },
    loadDoctor(id) {
      this.doctor = null;

      this.axios.get('/doctors/' + id)
        .then(res => {
          this.doctor = res.data.data;
        })
    },
    readURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.form.image = e.target.result;
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
    chooseImage() {
      this.$refs.imageInput.click();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/doctors/' + this.doctor.id
      this.form._method = 'PATCH'

      this.axios.post(url, this.form)
        .then(() => {
          this.edit = false;
          this.loadDoctor(this.doctor.id);
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>