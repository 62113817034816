<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-doctor-slot-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            ลงเวลาวันทำงานของแพทย์
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <img :src="doctorImage">
            </div>
            <div class="col-md-9">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('doctor_id')}]">
                <label class="col-sm-4 col-form-label">เลือกแพทย์</label>
                <div :class="['col-sm-8 form-group mb-2', {'error': errorFor('doctor_id')}]">
                  <select class="form-control form-control-sm select2"
                          id="doctor-select"
                          style="width: 100%;">
                    <option value="">เลือกแพทย์</option>
                    <option :value="doctor.id"
                            v-for="doctor in doctors"
                            :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
                  </select>

                  <v-errors :errors="errorFor('doctor_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-2', {'error': errorFor('start') || errorFor('end')}]">
                <label class="col-sm-4 col-form-label">เวลาเข้างาน</label>
                <div class="col-sm-4">
                  <div class="input-group bootstrap-timepicker">
                    <input type="text"
                           data-form="start"
                           class="form-control form-control-sm timepicker">

                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>

                  <v-errors :errors="errorFor('start')"></v-errors>
                </div>
                <div class="col-sm-4">
                  <div class="input-group bootstrap-timepicker">
                    <input type="text"
                           data-form="end"
                           class="form-control form-control-sm timepicker">

                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>

                  <v-errors :errors="errorFor('end')"></v-errors>
                </div>
              </div>
              <div class="form-group form-group-sm row mb-0">
                <label class="col-sm-4 col-form-label">ระยะเวลาต่อเคส</label>
                <div class="col-sm-4">
                  <select class="form-select form-select-sm"
                          v-model="form.duration">
                    <option value="60">1 ชั่วโมง</option>
                    <option value="120">2 ชั่วโมง</option>
                    <option value="180">3 ชั่วโมง</option>
                    <option value="240">4 ชั่วโมง</option>
                    <option value="300">5 ชั่วโมง</option>
                    <option value="360">6 ชั่วโมง</option>
                  </select>
                </div>
              </div>
              <div  :class="['form-group form-group-sm row mb-0', {'error': errorFor('days')}]">
                <label class="col-sm-4 col-form-label">จำนวนวันที่เข้า</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm mb-2"
                         placeholder="ชื่อ"
                         v-model="form.days"
                         type="text">

                  <v-errors :errors="errorFor('days')"></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-8">
                  <div class="box">
                    <div class="box-body p-3 bg-secondary-light">
                      <h5 class="text-bold">ระบุวันที่เข้า</h5>

                      <div class="form-group form-group-sm"
                           v-for="day in numOfDay"
                           :key="'day_'+day">
                        <div class="input-group mb-3">
                          <span class="input-group-text">วันที่ {{ day }}</span>
                          <input type="date"
                                 name="date"
                                 v-model="form.dates[day-1]"
                                 class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      doctors: [],
      form: {
        doctor_id: '',
        start: '',
        end: '',
        duration: '60',
        days: 5,
        dates: []
      }
    }
  },
  computed: {
    numOfDay() {
      if (this.form.days !== '' && !isNaN(parseInt(this.form.days)))
        return parseInt(this.form.days);

      return 0;
    },
    doctorImage() {
      let user = this.doctors.find(d => d.id == this.form.doctor_id);

      if (user && user.image)
        return user.image;
      else
        return '/images/avatar-girl.png';
    }
  },
  created() {
    this.axios.get('/doctors')
      .then(res => {
        this.doctors = res.data.data;

        this.initSelect2();
      })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-doctor-slot-modal'));

    this.initTimePicker();
  },
  methods: {
    resetForm() {
      this.allErrors = null;

      this.form = {
        doctor_id: '',
          start: '',
          end: '',
          duration: '60',
          days: 5,
          dates: []
      }
    },
    show() {
      this.resetForm();
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    initTimePicker() {
      let self = this;

      window.$('.timepicker').timepicker({
        showInputs: false,
        defaultTime: false,
        minuteStep: 10,
      }).on('changeTime.timepicker', function(e) {
        let field = window.$(this).data('form');

        self.form[field] = e.time.value;
      });
    },
    initSelect2() {
      let self = this;

      window.$('#doctor-select').select2({
        dropdownParent: window.$('#add-new-doctor-slot-modal')
      });

      window.$('#doctor-select').on('change.select2', function () {
        self.form.doctor_id = window.$(this).val();
      });
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/doctor-slots', this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>