<template>
  <div class="modal fade bs-example-modal-lg"
       id="edit-doctor-slot-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" v-if="doctorSlot">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Edit Slot ID : {{ doctorSlot.slot_no }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3">
              <img :src="doctorSlot.doctor.image ? doctorSlot.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="w-p100">
            </div>
            <div class="col-9">
              <div class="form-group form-group-sm row mb-0">
                <label class="col-sm-2 col-form-label">Doctor</label>
                <div class="col-sm-10">
                  <input class="form-control form-control-sm"
                         :value="doctorSlot.doctor.full_name"
                         type="text" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm row mb-0">
                <label class="col-sm-2 col-form-label">Slot No.</label>
                <div class="col-sm-10">
                  <input class="form-control form-control-sm"
                         :value="doctorSlot.slot_no"
                         type="text" disabled>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('date')}]">
                <label class="col-sm-2 col-form-label">Date</label>
                <div class="col-sm-10">
                  <input class="form-control form-control-sm"
                         v-model="form.date"
                         type="date">

                  <v-errors :errors="errorFor('date')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('start')}]">
                <label class="col-sm-2 col-form-label">Time</label>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.start"
                         type="time">

                  <v-errors :errors="errorFor('start')"></v-errors>
                </div>
                <div class="col-sm-5">
                  <input class="form-control form-control-sm"
                         v-model="form.end"
                         type="time">

                  <v-errors :errors="errorFor('end')"></v-errors>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <input class="d-none"
         type="file"
         @change="readURL($event.target)"
         ref="imageInput"
         accept="image/*">
</template>

<script>
export default {
  props: ['doctorSlot'],
  data() {
    return {
      modal: null,
      form: {
        id: '',
        date: '',
        start: '',
        end: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('edit-doctor-slot-modal'));
  },
  methods: {
    show() {
      setTimeout(() => {
        this.form.id = this.doctorSlot.id;
        this.form.date = this.doctorSlot.date_raw;
        this.form.start = this.doctorSlot.start_raw;
        this.form.end = this.doctorSlot.end_raw;
        this.modal.show();
      }, 100)
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.patch('/doctor-slots/' + this.form.id, this.form)
        .then(() => {
          this.modal.hide();
          this.$emit('updated');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>