<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('doctor.doctors') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('doctor.doctors') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-11">
      <div class="input-group">
        <input type="text"
               @keyup="searchText($event.target.value)"
               placeholder="ค้นหาด้วยชื่อหมอ"
               class="form-control">
        <span class="input-group-text"><i class="fa fa-search"></i></span>
      </div>
    </div>
    <div class="col-md-1">
      <div class="d-grid">
        <button @click="$refs.addNewDoctor.show()"
                class="btn btn-primary btn-sm">+ {{ $t('doctor.add_new') }}</button>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-6 col-lg-3" v-for="doctor in doctors"
         :key="'doctor_'+doctor.id">
      <div class="box ribbon-box">
        <div class="box-body text-center">
          <img :src="doctor.image ? doctor.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="h-150">
          <div class="text-center">
            <h3 class="my-10">
              <a href="#" @click.prevent="$refs.doctorDetailsModal.show(doctor.id)">{{ doctor.full_name }}</a>
            </h3>
            <h6 class="user-info mt-0 mb-10 text-fade">{{ doctor.department.name }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-new-doctor ref="addNewDoctor" @added="loadData" />
  <doctor-details-modal ref="doctorDetailsModal" />
</template>

<script>
import AddNewDoctor from "@/views/doctor/modals/AddNewDoctor";
import DoctorDetailsModal from "@/views/doctor/modals/DoctorDetailsModal";
export default {
  components: {AddNewDoctor, DoctorDetailsModal},
  data() {
    return {
      doctors: [],
      queryParams: {
        search: '',
        search_columns: 'full_name,email,phone'
      },
    }
  },
  created() {
    this.axios.get('/doctors')
      .then(res => {
        this.doctors = res.data.data;
      })
  },
  methods: {
    searchText(q) {
      this.queryParams.search = q;
      this.loadData();
    },
    loadData() {
      this.doctors = [];

      this.axios.get('/doctors', {params: this.queryParams})
        .then((response) => {
          this.doctors = response.data.data;
        })
    },
  }
}
</script>