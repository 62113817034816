<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-doctor-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3">
              <div class="text-center">
                <img class="max-h-200"
                     onerror="this.src='/images/avatar-girl.png'"
                     :src="form.image ? form.image : '/images/avatar-girl.png'" > <br>

                <input class="d-none"
                       type="file"
                       @change="readURL($event.target)"
                       ref="imageInput"
                       accept="image/*">

                <button type="button"
                        @click.prevent="chooseImage"
                        class="btn-sm mt-5 waves-effect waves-light btn btn-outline btn-primary">
                  <i class="far fa-image"></i> เปลี่ยนรูปโปรไฟล์
                </button>
              </div>
            </div>
            <div class="col-9">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('first_name') || errorFor('last_name')}]">
                <label class="col-sm-4 col-form-label">ชื่อแพทย์ <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm mb-2"
                         placeholder="ชื่อ"
                         v-model="form.first_name"
                         type="text">

                  <v-errors :errors="errorFor('first_name')"></v-errors>

                  <input class="form-control form-control-sm mb-2"
                         placeholder="นามสกุล"
                         v-model="form.last_name"
                         type="text">

                  <v-errors :errors="errorFor('last_name')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('phone')}]">
                <label class="col-sm-4 col-form-label">เบอร์โทร <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         placeholder="เบอร์โทร"
                         v-model="form.phone"
                         type="text">

                  <v-errors :errors="errorFor('phone')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('email')}]">
                <label class="col-sm-4 col-form-label">อีเมล <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         placeholder="อีเมล"
                         v-model="form.email"
                         type="text">

                  <v-errors :errors="errorFor('email')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('department_id')}]">
                <label class="col-sm-4 col-form-label">แผนก</label>
                <div class="col-sm-8">
                  <select class="form-select form-select-sm"
                          v-model="form.department_id">
                    <option value="">เลือก</option>
                    <option :value="department.id"
                            v-for="department in departments"
                            :key="'department_'+department.id">{{ department.name }}</option>
                  </select>

                  <v-errors :errors="errorFor('department_id')"></v-errors>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  @click="submit"
                  :disabled="loading">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      departments: [],
      title: '',
      doctor_id: '',
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        department_id: '',
        image: '',
      }
    }
  },
  created() {
    this.axios.get('/departments')
      .then(res => {
        this.departments = res.data.data;
      });
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-doctor-modal'));
  },
  methods: {
    resetForm() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = '';
      })
    },
    show(doctor = null) {
      this.resetForm();

      if (doctor) {
        this.form = {...this.form, ...doctor};
        this.doctor_id = doctor.id;

        delete this.form.id;

        this.title = 'Edit Doctor';
      } else {
        this.doctor_id = '';
        this.title = 'Add Doctor';
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    chooseImage() {
      this.$refs.imageInput.click();
    },
    readURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.form.image = e.target.result;
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/doctors';

      if (this.doctor_id) {
        url = '/doctors/' + this.doctor_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>