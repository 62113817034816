<template>
  <span class="badge badge-warning" v-if="status == 0">จอง</span>
  <span class="badge badge-info" v-else-if="status == 1">ยืนยัน</span>
  <span class="badge badge-primary" v-else-if="status == 2">เช็คอิน</span>
  <span class="badge badge-success" v-else-if="status == 3">เสร็จสิ้น</span>
  <span class="badge badge-secondary" v-else-if="status == 4">ยกเลิก</span>
</template>

<script>
export default {
  props: ['status'],
}
</script>