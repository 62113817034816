<template>
  <!-- Control Sidebar -->
  <aside class="control-sidebar inv-req-side">

    <div class="rpanel-title"><span class="pull-right btn btn-circle btn-danger" data-toggle="control-sidebar"><i class="ion ion-close text-white"></i></span> </div>  <!-- Create the tabs -->

    <h4 class="mt-20">{{ date }}</h4>

    <hr>

    <div class="mb-10 d-flex justify-content-between align-items-center"
         v-for="slot in slots"
         :key="'slot_'+slot.id">
      <div class="fw-600 min-w-70">
        {{ slot.start_time_format }}
      </div>
      <a class="w-p100 p-10 rounded10 justify-content-between align-items-center d-flex bg-lightest"
         href="#" @click.prevent="openCaseDetails(slot.case.id)">
        <div class="d-flex justify-content-between align-items-center">
          <img src="/images/avatar-2.png" class="me-10 avatar rounded-circle" alt="">
          <div>
            <h6 class="mb-0">{{ slot.doctor.full_name }}</h6>
            <p class="mb-0 fs-12 text-mute">{{ slot.case.services.map(s => s.name_th).join(', ') }}</p>
          </div>
        </div>
      </a>
    </div>
  </aside>
  <!-- /.control-sidebar -->
</template>

<script>
export default {
  props: ['slots', 'date'],
  methods: {
    openCaseDetails(caseId) {
      let routeData = this.$router.resolve({name: 'case_notes', params: {id: caseId}});
      window.open(routeData.href, '_blank');
    }
  }
}
</script>